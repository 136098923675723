import { EUnit } from '../types/enums';

import type { EField } from '../types/enums';

export const fieldUnitMapping: Record<EField, EUnit> = {
    humidity: EUnit.percent,
    temperature: EUnit.celsius,
    pressure: EUnit.hpa,
    battery: EUnit.volt,
    rssi: EUnit.dbm,
};

export const fieldColorMapping: Record<EField, string> = {
    humidity: '#2cf',
    temperature: '#fa0',
    pressure: '#fff',
    battery: '#0d4',
    rssi: '#f2f',
};

export const unitLabel: Record<EUnit, string> = {
    percent: '%',
    celsius: '°C',
    hpa: 'hPa',
    ms2: 'm/s²',
    dbm: 'dBm',
    volt: 'V',
};

type CompareStringInput = string | null | undefined;

export const stringSortFn = (a: CompareStringInput, b: CompareStringInput, numeric = true) => {
    const comparison = (a ?? '').localeCompare(b ?? '', 'fr', { numeric });

    if (comparison < 0) {
        return -1;
    } else if (comparison > 0) {
        return 1;
    }

    return 0;
};

export const sortBy = <T, G extends (array: T) => CompareStringInput>(array: T[], getter: G, numeric = true) => {
    return [...array].sort((a, b) => stringSortFn(getter(a), getter(b), numeric));
};
