import axios from 'axios';
import { init } from 'echarts';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { getDeviceMeasurements } from './api/deviceService';
import FetchStatus from './components/FetchStatus';
import FieldSelector from './components/FieldSelector';
import XRangeSelector from './components/XRangeSelector';
import LastMeasurements from './LastMeasurements';
import { EField, ETimeUnit, EXRangeType } from './types/enums';
import {
    baseChart,
    baseGrid,
    baseYAxis,
    getFieldSeries,
    getSeriesLegend,
    getUnitYAxis,
    loadingOptions,
    resetZoomAction,
    yAxisWidth,
} from './utils/echartsUtils';
import { fieldUnitMapping } from './utils/entityUtils';
import { deviceCheckbox } from './utils/templates';

import type { AbsoluteXRange, DataZoomEvent, RelativeXRange } from './types/chart';
import type { Device, Measurement } from './types/entities';
import type { CancelToken, CancelTokenSource } from 'axios';
import type { EChartsType, LegendComponentOption, SeriesOption } from 'echarts';
import type { GridOption, YAXisOption } from 'echarts/types/dist/shared';

type Props = {
    devices: Device[];
};

export default function MainChart({ devices }: Props) {
    const chartDomRef = useRef<HTMLDivElement>(null);
    const zoomXRangeRef = useRef<AbsoluteXRange | null>(null);
    const controllerRef = useRef<CancelTokenSource | null>(null);
    const latestRequestIdRef = useRef<number>(0);

    const [displayedDevices, setDisplayedDevices] = useState<Set<number>>(new Set([2]));
    const [displayedFields, setDisplayedFields] = useState<Set<EField>>(new Set([EField.temperature, EField.humidity]));
    const [resolution] = useState<number>(500);
    const [measurements, setMeasurements] = useState<Measurement[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [fetchedAt, setFetchedAt] = useState<Date | null>(null);
    const [chart, setChart] = useState<EChartsType | null>(null);

    // xRange
    const [rangeType, setRangeType] = useState<EXRangeType>(EXRangeType.relative);
    const [relativeXRange, setRelativeXRange] = useState<RelativeXRange>({ amount: 2, unit: ETimeUnit.d });
    const [absoluteXRange, setAbsoluteXRange] = useState<AbsoluteXRange>({ startDate: null, endDate: null });
    const [zoomXRange, setZoomXRange] = useState<AbsoluteXRange | null>(null);

    const fetchDeviceMeasurements = useCallback(
        async (device: Device, cancelToken: CancelToken) => {
            let relativeXRangeStr: string | undefined = undefined;
            let from: string | undefined = undefined;
            let to: string | undefined = undefined;
            const zoom = zoomXRangeRef.current;

            if (zoom) {
                from = zoom.startDate ?? undefined;
                to = zoom.endDate ?? undefined;
            } else if (rangeType === EXRangeType.relative) {
                relativeXRangeStr = `${relativeXRange.amount}${relativeXRange.unit}`;
            } else {
                from = absoluteXRange.startDate ?? undefined;
                to = absoluteXRange.endDate ?? undefined;
            }

            return await getDeviceMeasurements(cancelToken, device, relativeXRangeStr, from, to, resolution)
                .then(response => {
                    const measurements: Measurement[] = response.data.map(m => ({
                        ...m,
                        device,
                        time: new Date(m.measured_at),
                    }));

                    return measurements;
                })
                .catch(e => {
                    if (e.message !== 'canceled') {
                        console.error(e.message);
                    }
                    return [];
                });
        },
        [zoomXRange, rangeType, relativeXRange, absoluteXRange, resolution],
    );

    const fetchMeasurements = useCallback(async () => {
        controllerRef.current?.cancel();
        const controller = axios.CancelToken.source();
        controllerRef.current = controller;
        const requestId = ++latestRequestIdRef.current;

        setFetching(true);
        chart?.showLoading(loadingOptions);

        await Promise.all(devices.map(d => fetchDeviceMeasurements(d, controller.token)))
            .then(responses => {
                setMeasurements(responses.flat());
                setFetchedAt(new Date());
            })
            .catch(e => {
                console.error(e);
                setMeasurements([]);
                setFetchedAt(null);
            })
            .finally(() => {
                if (requestId === latestRequestIdRef.current) {
                    setFetching(false);
                    chart?.hideLoading();
                }
            });
    }, [chart, devices, fetchDeviceMeasurements]);

    // fetch measurements
    useUpdateEffect(() => {
        fetchMeasurements();
    }, [devices, rangeType, relativeXRange, absoluteXRange, zoomXRange, resolution]);

    // on xRange change, reset zoom
    useUpdateEffect(() => {
        zoomXRange && chart?.dispatchAction(resetZoomAction);
    }, [rangeType, relativeXRange, absoluteXRange]);

    // init chart
    useEffect(() => {
        let _chart: EChartsType | null = null;

        if (chartDomRef.current) {
            _chart = init(chartDomRef.current, 'dark');
            _chart.setOption(baseChart);

            _chart.on('dataZoom', e => {
                const zoomData = (e as DataZoomEvent).batch[0];
                let _zoomXRange: AbsoluteXRange | null = null;

                if (zoomData.startValue && zoomData.endValue) {
                    _zoomXRange = {
                        startDate: new Date(zoomData.startValue).toISOString(),
                        endDate: new Date(zoomData.endValue).toISOString(),
                    };
                }

                zoomXRangeRef.current = _zoomXRange;
                setZoomXRange(_zoomXRange);
            });

            setChart(_chart);
        }

        const onResize = () => _chart?.resize();
        window.addEventListener('resize', onResize);

        return () => {
            _chart?.dispose();
            window.removeEventListener('resize', onResize);
            controllerRef.current?.cancel();
        };
    }, []);

    // update chart series & legend
    useEffect(() => {
        if (!chart) {
            return;
        }

        const units = new Set([...displayedFields].map(f => fieldUnitMapping[f]));
        const yAxis: YAXisOption[] = units.size ? [...units].map(getUnitYAxis) : baseYAxis;
        const grid: GridOption = {
            ...baseGrid,
            left: Math.ceil(yAxis.length / 2) * yAxisWidth,
            right: Math.floor(yAxis.length / 2) * yAxisWidth,
        };
        const series: SeriesOption[] = [];
        const legends: LegendComponentOption[] = [];

        devices
            .filter(d => displayedDevices.has(d.id))
            .forEach((d, di) => {
                const dMeasurements = measurements.filter(m => m.device.id === d.id);
                const dSeries = [...displayedFields].map(f => getFieldSeries(d, f, dMeasurements));
                series.push(...dSeries);
                legends.push(getSeriesLegend(di, d, dSeries));
            });

        chart.setOption({ series, yAxis, legend: legends, grid }, { replaceMerge: ['series', 'yAxis'] });
    }, [measurements, displayedDevices, displayedFields]);

    return (
        <div className='h-full flex flex-column gap-5'>
            <div className='flex justify-content-between gap-5'>
                <LastMeasurements devices={devices} />
                <div className='flex align-items-center gap-3'>
                    <div className='flex gap-3'>
                        <div className='flex justify-content-center gap-3'>
                            {devices.map(device => deviceCheckbox(device, displayedDevices, setDisplayedDevices))}
                        </div>
                        <div>
                            <Button
                                className='field-selector p-2'
                                type='button'
                                icon='pi pi-filter'
                                size='large'
                                text
                            />
                            <Tooltip target='.field-selector' autoHide={false} position='bottom'>
                                <FieldSelector fields={displayedFields} setFields={setDisplayedFields} />
                            </Tooltip>
                        </div>
                    </div>
                    <XRangeSelector
                        rangeType={rangeType}
                        relativeXRange={relativeXRange}
                        absoluteXRange={absoluteXRange}
                        setRangeType={setRangeType}
                        setRelativeXRange={setRelativeXRange}
                        setAbsoluteXRange={setAbsoluteXRange}
                    />
                    <FetchStatus isFetching={fetching} fetchedAt={fetchedAt} triggerFetch={fetchMeasurements} />
                </div>
            </div>
            <div ref={chartDomRef} style={{ width: '100%', height: '100%' }} />
        </div>
    );
}
