import { EField } from '../types/enums';
import { fieldColorMapping, fieldUnitMapping, unitLabel } from './entityUtils';
import { tooltipHtml } from './templates';

import type { Device, Measurement } from '../types/entities';
import type { EUnit } from '../types/enums';
import type { TooltipCallbackParams } from './templates';
import type { EChartsOption, LegendComponentOption, Payload, SeriesOption, ToolboxComponentOption } from 'echarts';
import type { XAXisOption, YAXisOption } from 'echarts/types/dist/shared';
import type { ValueAxisBaseOption } from 'echarts/types/src/coord/axisCommonTypes.js';

export const loadingOptions: object = {
    color: '#aaa',
    maskColor: '#111a',
    text: '',
    spinnerRadius: 18,
    lineWidth: 2,
};

export const resetZoomAction: Payload = {
    type: 'dataZoom',
    batch: [
        {
            start: 0,
            end: 100,
            startValue: undefined,
            endValue: undefined,
        },
    ],
};

const tooltip: EChartsOption['tooltip'] = {
    trigger: 'axis',
    backgroundColor: '#000c',
    borderColor: '#ccc5',
    formatter: (params: unknown) => tooltipHtml(params as TooltipCallbackParams[]),
    axisPointer: {
        type: 'cross',
        label: {
            color: '#ccc',
            backgroundColor: '#000',
            borderColor: '#444',
            borderWidth: 1,
            fontSize: 11,
            padding: [4, 4, 3, 4],
            margin: 5,
        },
        lineStyle: { color: '#777' },
        crossStyle: { color: '#777' },
    },
};

const toolbox: ToolboxComponentOption = { feature: { dataZoom: { yAxisIndex: 'none' } } };

const xAxis: XAXisOption = {
    id: 'xAxis',
    type: 'time',
    axisTick: { length: 3 },
    axisLine: { lineStyle: { color: '#444' } },
    axisLabel: { fontSize: 12, color: '#ddd' },
};

export const baseYAxis: YAXisOption[] = [{ type: 'value' }];

export const yAxisWidth = 50;

export const baseGrid: EChartsOption['grid'] = {
    top: 75,
    right: 10,
    bottom: 25,
    left: 0,
};

export const baseChart: EChartsOption = {
    backgroundColor: 'transparent',
    toolbox,
    tooltip,
    xAxis,
    yAxis: baseYAxis,
    grid: baseGrid,
    emphasis: { focus: 'series' },
    textStyle: { fontSize: 12 },
};

const unitScaleMapping: Record<EUnit, ValueAxisBaseOption['scale']> = {
    percent: false,
    celsius: false,
    hpa: true,
    ms2: true,
    dbm: false,
    volt: true,
};

export const getUnitYAxis = (unit: EUnit, i: number): YAXisOption => ({
    id: unit,
    name: unitLabel[unit],
    type: 'value',
    position: i % 2 ? 'right' : 'left',
    alignTicks: true,
    nameGap: 20,
    offset: Math.floor(i / 2) * yAxisWidth,
    scale: unitScaleMapping[unit],
    axisLabel: { fontSize: 12, color: '#ddd' },
    splitLine: { lineStyle: { color: '#222' } },
});

export const getFieldSeries = (device: Device, field: EField, measurements: Measurement[]): SeriesOption => {
    const series: SeriesOption = {
        id: `${device.id}-${field}`,
        yAxisId: fieldUnitMapping[field],
        name: `[${device.display_name[0]}] ${field}`,
        type: 'line',
        showSymbol: false,
        animation: false,
        lineStyle: {
            type: device.id === 1 ? 'dashed' : 'solid',
            color: fieldColorMapping[field],
            width: 1,
        },
        itemStyle: {
            color: '#000',
            borderColor: fieldColorMapping[field],
            borderWidth: 1,
        },
        data: measurements.map(m => [m.time.getTime(), m[field]]),
    };

    if (field === EField.humidity) {
        series.lineStyle = {
            ...series.lineStyle,
            opacity: 0.75,
        };
        series.areaStyle = {
            color: fieldColorMapping[field],
            opacity: 0.2,
        };
    }

    return series;
};

export const baseLegend: LegendComponentOption = {
    textStyle: {
        fontFamily: 'monospace',
        fontSize: 13,
        color: '#eee',
    },
    itemHeight: 8,
    itemWidth: 16,
    lineStyle: { width: 1 },
    itemStyle: { opacity: 0 },
};

export const getSeriesLegend = (i: number, device: Device, deviceSeries: SeriesOption[]): LegendComponentOption => ({
    ...baseLegend,
    name: device.display_name,
    top: i * 20,
    data: deviceSeries.map(s => s.name as string),
});
