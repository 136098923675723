import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MeasurementSummary from './components/MeasurementSummary';
import RssiChart from './RssiChart';

import type { Device, Measurement, RawMeasurement } from './types/entities';
import type { CSSProperties } from 'react';

type Props = {
    devices: Device[];
};

export default function LastMeasurements({ devices }: Props) {
    const rssiPanelRef = useRef<OverlayPanel>(null);

    const [measurements, setMeasurements] = useState<Measurement[]>([]);
    const [lastByDevice, setLastByDevice] = useState<Map<number, Measurement>>(new Map());

    const sseHistoryLength = useMemo(() => 60000, []);
    const lastGridCss: CSSProperties = useMemo(
        () => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(4, auto)',
            alignContent: 'space-between',
            columnGap: '1rem',
            cursor: 'default',
        }),
        [devices],
    );

    const handleMeasurementEvent = useCallback(
        (event: Event) => {
            if (!devices) {
                console.info('devices not fetched yet, skipping measurement event');
                return;
            }

            const rawMeasurement = (event as CustomEvent<RawMeasurement>).detail;
            const device = devices.find(device => device.id === rawMeasurement.device_id);

            if (!device) {
                console.error('device not found');
                return;
            }

            const measurement: Measurement = {
                ...rawMeasurement,
                time: new Date(rawMeasurement.measured_at),
                device,
            };

            const minTime = new Date(Date.now() - sseHistoryLength);
            setMeasurements(prev => [...prev.filter(m => m.time > minTime), measurement]);
            setLastByDevice(prev => new Map(prev).set(device.id, measurement));
        },
        [devices, setMeasurements],
    );

    const deviceLastTemplate = useCallback(
        (device: Device) => (
            <MeasurementSummary key={device.id} device={device} measurement={lastByDevice.get(device.id)} />
        ),
        [lastByDevice],
    );

    // listen measurement events
    useEffect(() => {
        window.addEventListener('measurementEvent', handleMeasurementEvent);

        return () => {
            window.removeEventListener('measurementEvent', handleMeasurementEvent);
        };
    }, [devices]);

    return (
        <div className='flex align-items-stretch gap-4'>
            <div>
                <OverlayPanel ref={rssiPanelRef} style={{ width: '500px', backgroundColor: '#050505' }}>
                    <RssiChart devices={devices} measurements={measurements} />
                </OverlayPanel>
                <Button
                    className='rssi-chart'
                    type='button'
                    icon='pi pi-wave-pulse'
                    severity='success'
                    outlined
                    onMouseEnter={e => rssiPanelRef.current?.show(e, undefined)}
                    onMouseLeave={() => rssiPanelRef.current?.hide()}
                />
            </div>
            <div style={lastGridCss}>{devices.map(deviceLastTemplate)}</div>
        </div>
    );
}
