import { useEffect, useState } from 'react';
import { getDevices } from './api/deviceService';
import MainChart from './MainChart';

import type { Device } from './types/entities';

export default function Home() {
    const [devices, setDevices] = useState<Device[]>();

    // fetch devices
    useEffect(() => {
        getDevices()
            .then(res => setDevices(res.data))
            .catch(console.error);
    }, []);

    // while devices are fetching, display spinner
    if (!devices) {
        return (
            <div className='flex justify-content-center mt-4'>
                <i className='pi pi-spin pi-spinner' />
            </div>
        );
    }

    return (
        <div className='flex flex-column p-4' style={{ height: '100vh', width: '100vw' }}>
            <MainChart devices={devices} />
        </div>
    );
}
